import { faAngleLeft, faAngleRight, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import classes from '../../index.module.css'
import { HomepageProps } from '../../../../pages'
import Image from 'next/image'
import NewCarousel from '../../../NewCarousel'
import LegacyCarousel from '../../../LegacyCarousel'

interface PromotionsProps {
    homepageBanner: HomepageProps['listData']['homepage-banner']
}

const DesktopPromotions = (props: PromotionsProps) => {
    return (
        <div className="w-full mb-10 lg:mb-0 pb-0 lg:pb-16 relative">
            <div className='lg:container absolute lg:relative lg:inset-auto bottom-43 z-1 flex flex-col items-end mb-0 lg:mb-8' style={{right: 0, bottom: 32}}>
                <a href='/otherpromotion' className='lg:absolute lg:top-75 lg:right-43 bg-red-#FFE0E2 flex items-center lg:opacity-75 justify-center relative transition-colors duration-100 lg:text-white lg:bg-black-222 text-red-2438 py-2 px-4 text-sm rounded-sm outline-none focus:outline-none hover:bg-red-300 hover:text-white'>
                    View all promotions
                    <FontAwesomeIcon icon={faLongArrowAltRight} size="1x" width="" className='ml-2' />
                </a>
            </div>
            <div className='w-full overflow-hidden flex flex-row relative'>
                <div className='flex-auto z-2 bg-gradient-to-r from-white to-transparent hidden lg:block'></div>
                <div className={`${classes.promotionContainer} relative w-full lg:mx-auto lg:container`}>
                    <div className='w-full'>
                        <LegacyCarousel
                            show={1}
                            leftArrow={prev =>
                                <div className="hidden lg:block absolute z-1 transform top-1/2 -translate-y-1/2 -translate-x-1/2" style={{ left: 10 }}>
                                    <button onClick={prev} className="bg-main hover:bg-main-dark active:bg-main-dark w-47px h-47px rounded outline-none focus:outline-none transition-colors duration-150 ease-linear flex items-center justify-center">
                                        <FontAwesomeIcon icon={faAngleLeft} width="0" className="text-white" size="lg" />
                                    </button>
                                </div>
                            }
                            rightArrow={next =>
                                <div className="hidden lg:block absolute z-1 transform top-1/2 -translate-y-1/2 translate-x-1/2" style={{ right: 10 }}>
                                    <button onClick={next} className="bg-main hover:bg-main-dark active:bg-main-dark w-47px h-47px rounded outline-none focus:outline-none transition-colors duration-150 ease-linear flex items-center justify-center">
                                        <FontAwesomeIcon icon={faAngleRight} width="0" className="text-white" size="lg" />
                                    </button>
                                </div>
                            }
                            withIndicator
                            isRepeatedProps
                            withoutArrow={false}
                            withoutHideOverflow
                        >
                            {
                                props.homepageBanner.map((banner) =>
                                // (banner.url && banner.url !== '') ?
                                (
                                    <a href={banner.url} target="_blank" rel="noopener norefferrer" className="block w-full flex-shrink-0 mb-3 px-0 lg:px-3" key={banner.id} >
                                        <div className='relative hidden w-full lg:flex items-center justify-center'>
                                            <Image
                                                loading='lazy'
                                                width={1130}
                                                height={300}
                                                // layout="fill"
                                                src={banner.image}
                                                alt="banner" />
                                        </div>
                                        <div className='relative lg:hidden flex w-full h-full items-center justify-center' style={{ aspectRatio: '6/4' }}>
                                            <Image
                                                width={360}
                                                height={240}
                                                loading='lazy'
                                                layout='fill'
                                                src={banner.image_mobile}
                                                alt="banner" />
                                        </div>
                                    </a>
                                )
                                    // :
                                    // <div className="block w-full flex-shrink-0 lg:px-3" key={banner.id} >
                                    //     <div className='relative hidden mb-3 w-full lg:flex items-center justify-center'>
                                    //         <Image
                                    //             loading='lazy'
                                    //             width={1130}
                                    //             height={300}
                                    //             // layout="fill"
                                    //             src={banner.image}
                                    //             alt="banner" />
                                    //     </div>
                                    //     <div className='relative flex mb-3 w-full lg:hidden items-center justify-center flex-shrink-0'>
                                    //         <Image
                                    //             width={360}
                                    //             height={240}
                                    //             loading='lazy'
                                    //             // sizes='100%'
                                    //             src={banner.image_mobile}
                                    //             alt="banner" />
                                    //     </div>
                                    // </div>

                                )
                            }
                        </LegacyCarousel>
                    </div>
                </div>
                <div className='flex-auto z-2 bg-gradient-to-l from-white to-transparent hidden md:block'></div>
            </div>
        </div>
    )
}

export default React.memo(DesktopPromotions)
