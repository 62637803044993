import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import dynamic from 'next/dynamic';
import classes from './index.module.css'

/**
 * Components
 */
import Master from "../layouts/Master"

const Promotions = dynamic(() => import('./components/homepage/Promotions'));
const SmartMoveSection = dynamic(() => import('./components/homepage/SmartMoveSection'));
const NewLaunch = dynamic(() => import('./components/homepage/NewLaunch'))
const ExploreCities = dynamic(() => import('./components/homepage/ExploreCities'))
const ExploreProject = dynamic(() => import('./components/homepage/ExploreProject'))
const MeetOurTopAgents = dynamic(() => import('./components/homepage/MeetOurTopAgents'))
const Your360Dream = dynamic(() => import('./components/homepage/Your360Dream'))
const ArticleListSection = dynamic(() => import('./article/ArticleListSection'))

const ReactAutoSuggest = dynamic(() => import('react-autosuggest'))
const LegacyCarousel = dynamic(() => import('../LegacyCarousel'))
const SearchLight = dynamic(() => import('../icons/SearchLight'))
const Select = dynamic(() => import('../forms/Select'));

/**
 * Utils
 */
import { getLanguage, generateUrl, getCurrentBreakpoint, escapeRegex, inputNumber, serializeToQuery } from '../../utils/Helper';
import Axios from 'axios';
import { debounce, property } from 'lodash'
import { PropertyType } from '../../utils/Types';
import { DEFAULT_IMAGE, PRICE } from '../../utils/Constant';
import { HomepageProps } from '../../pages';

/**
 * Icons
*/
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AerialViewIcon from '../icons/AerialViewIcon';
import { faAngleDown, faAngleLeft, faAngleRight, faChevronLeft, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import Image from 'next/image';
import HomepageArticle from './components/homepage/HomepageArticle';
import FilterModal from './homepage/FilterModal';
import FilterLight from '../icons/FilterLight';
import MapPin from '../icons/MapPin';
import HomepageCompare from '../icons/HomepageCompare';
import HomepageCalculatorIcon from '../icons/HomepageCalculatorIcon';
import Homepage360Icon from '../icons/Homepage360Icon';
import HomepagePromoIcon from '../icons/HomepagePromoIcon';
import HomepageContactAgentIcon from '../icons/HomepageContactAgentIcon';
import HomepageAerialIcon from '../icons/HomepageAerialIcon';
import DesktopPromotions from './components/homepage/DesktopPromotions';
import HelpfulToolsSection from './components/homepage/HelpfulToolsSection';


export interface IState {
    [key: string]: string | number | null
    search: string
    property_type: PropertyType
    min_price: string
    max_price: string
}

interface Props extends HomepageProps {
    langCode: string
    ldJsons?: Record<string, any>[]
}

export interface IProjectDeveloper {
    id: number
    name: string
    city_name: string
    is_apartment_project: 0 | 1
    location: string
    thumbnail_image: string
    url: string
    highlights: string
}

interface ISuggestion {
    title: string
    locations: ILocation[]
}

interface ILocation {
    ltext: string // name
    lt: string // type
    lid: number // id
    slug: string | null
    district: string | null // buat apartemen
    area: string | null // buat district
    location?: string
    image?: string // for clusters
}

interface ISelectedLocation {
    location_type: string
    location_id: string
    location_text: string
    rdr: string
}

interface IExploreProperty {
    renderIcon: any,
    imageUrl: string,
    title: string,
    amount: number,
    url: string
    backgroundClass: string
}

export interface IUnit {
    id: string
    is_featured: number
    latitude: number
    longitude: number
    location: string
    lowest_studio: number | null
    lowest_1_br: number | null
    lowest_2_br: number | null
    lowest_3_br: number | null
    lowest_kavling: number | null
    lowest_residential: number | null
    lowest_shophouse: number | null
    lowest_kiosk: number | null
    lowest_business_loft: number | null
    lowest_warehouse: number | null
    lowest_boutique_soho: number | null
    name: string
    price_start: number
    primary_image_thumbnail_path: string
    primary_image: string
    primary_image_thumbnail: string
    slug?: string
    project_slug: string
    is_promotion: boolean
    unit_count: number
    url: string
    area: number
    category: string
}

export interface IAgent {
    id: string,
    name: string,
    phone: string,
    project: string,
    total_cluster: number,
    unit_type: number,
    profile_description: string
    profile_picture: string
    clusters: IUnit[]
    slug: string
    unique_code: string
}

export interface IListingCounterCity {
    jakarta: number
    cikarang: number
    bsd: number
    cibubur: number
    balikpapan: number
    tangerang: number
    batam: number
    surabaya: number
}

export default function Index({ langCode, ldJsons, listData, promotionsData }: Props) {
    const lang: any = getLanguage('en')
    const searchRef = useRef<HTMLDivElement>(null)

    const [exploreProperty, setExploreProperty] = useState<IExploreProperty[]>([]);
    const [filterOpen, setFilterOpen] = useState(false)
    const [mobileSearchOpen, setMobileSearchOpen] = useState(false)
    const [mobileWidth, setMobileWidth] = useState(0)

    const [state, setState] = useState<IState>({
        search: '',
        property_type: 'All',
        min_price: '',
        max_price: '',
    })

    const filterCount = useMemo(() => {
        const keysCount = ['property_type', ...((state.min_price && state.max_price) ? ['min_price'] : ['min_price', 'max_price'])]
        let counter = 0

        Object.entries(state).forEach(([key, value]) => {
            if (keysCount.includes(key) && value) {
                if (key === 'property_type') {
                    if (value !== 'All') {
                        counter++
                    }
                } else {
                    counter++
                }
            }
        })

        return counter
    }, [state])

    const [initialSuggestions, setInitialSuggestions] = useState<ISuggestion[]>([])
    const [suggestions, setSuggestions] = useState<ISuggestion[]>([])

    const [breakpoint, setBP] = useState('')
    const [isMobile, setIsMobile] = useState(false)

    const [redirect, setRedirect] = useState<string | null>(null)

    const [selectedLocation, setSelectedLocation] = useState<ISelectedLocation | null>(null)

    const getToken = () => {
        try {
            const token = window.localStorage.getItem('token')
            if (token) {
                localStorage.setItem('fetch_profile', '1')
            }
        } catch {
            return ''
        }
    }

    useEffect(() => {
        getToken()

        const breakpoint = getCurrentBreakpoint()
        setIsMobile(breakpoint === 'xs' || breakpoint === 'sm')
        setBP(breakpoint)
        setMobileWidth(window.screen.width)

        window.addEventListener('resize', debouncedHandleResize)

        return () => {
            window.removeEventListener('resize', debouncedHandleResize)
        }
    }, [])

    const debouncedHandleResize = useCallback(debounce(() => {
        const breakpoint = getCurrentBreakpoint()
        setIsMobile(breakpoint === 'xs' || breakpoint === 'sm')
        setBP(breakpoint)
        setMobileWidth(window.screen.width)
    }, 250), [])

    useEffect(() => {
        if (mobileSearchOpen) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }

        return () => {
            document.body.style.overflow = 'auto'
        }
    }, [mobileSearchOpen])

    useEffect(() => {
        const HomeIcon = dynamic(() => import('../icons/HomeIcon'));
        const ApartmentIcon = dynamic(() => import('../icons/ApartmentIcon'));
        const ShophouseIcon = dynamic(() => import('../icons/ShophouseIcon'));
        const BusinessLoftIcon = dynamic(() => import('../icons/BusinessLoftIcon'));
        const KavlingLotIcon = dynamic(() => import('../icons/KavlingLotIcon'));
        const KioskIcon = dynamic(() => import('../icons/KioskIcon'));
        const BoutiqueIcon = dynamic(() => import('../icons/BoutiqueIcon'));
        const WarehouseIcon = dynamic(() => import('../icons/WarehouseIcon'));

        setExploreProperty([
            {
                renderIcon: (className: string) => <HomeIcon className={className} />,
                imageUrl: 'https://ecatalog.sinarmasland.com/assets/homepage/residential_bg.png',
                title: 'Residential',
                amount: listData.listing_counter.residential,
                url: generateUrl('id', '/residential'),
                backgroundClass: 'residential-bg'
            },
            {
                renderIcon: (className: string) => <ApartmentIcon className={className} />,
                imageUrl: 'https://ecatalog.sinarmasland.com/assets/homepage/apartment_bg.png',
                title: 'Apartment',
                amount: listData.listing_counter.apartment,
                url: generateUrl('id', '/apartment'),
                backgroundClass: 'apartment-bg'
            },
            {
                renderIcon: (className: string) => <ShophouseIcon className={className} />,
                imageUrl: 'https://ecatalog.sinarmasland.com/assets/homepage/shophouse_bg.png',
                title: 'Shophouse',
                amount: listData.listing_counter.shophouse,
                url: generateUrl('id', '/shophouse'),
                backgroundClass: 'shophouse-bg'
            },
            {
                renderIcon: (className: string) => <BusinessLoftIcon className={className} />,
                imageUrl: 'https://ecatalog.sinarmasland.com/assets/homepage/business_loft_bg.png',
                title: 'Business\nLoft',
                amount: listData.listing_counter.business_loft,
                url: generateUrl('id', '/businessloft'),
                backgroundClass: 'business-loft-bg'
            },
            {
                renderIcon: (className: string) => <KavlingLotIcon className={className} />,
                imageUrl: 'https://ecatalog.sinarmasland.com/assets/homepage/kavling_lot_bg.png',
                title: 'Kavling\nLot',
                amount: listData.listing_counter.kavling,
                url: generateUrl('id', '/kavling'),
                backgroundClass: 'kavling-lot-bg'
            },
            {
                renderIcon: (className: string) => <KioskIcon className={className} />,
                imageUrl: 'https://ecatalog.sinarmasland.com/assets/homepage/kiosk_bg.png',
                title: 'Kiosk',
                amount: listData.listing_counter.kiosk,
                url: generateUrl('id', '/kiosk'),
                backgroundClass: 'kiosk-bg'
            },
            {
                renderIcon: (className: string) => <WarehouseIcon className={className} />,
                imageUrl: 'https://ecatalog.sinarmasland.com/assets/homepage/warehouse.png',
                title: 'Warehouse',
                amount: listData.listing_counter.warehouse,
                url: generateUrl('id', '/warehouse'),
                backgroundClass: 'warehouse-bg'
            },
            {
                renderIcon: (className: string) => <BoutiqueIcon className={className} />,
                imageUrl: 'https://ecatalog.sinarmasland.com/assets/homepage/boutique_soho.png',
                title: 'Boutique\nSOHO',
                amount: listData.listing_counter.boutique_soho,
                url: generateUrl('id', '/boutiquesoho'),
                backgroundClass: 'boutique-bg'
            },
        ])
    }, [listData])

    const fetchList = (query?: string) => {
        Axios.get(`${process.env.NEXT_PUBLIC_PHP_API_URL}/search-autocomplete?query=${query || ''}`)
            .then(res => res.data)
            .then(res => {
                const cities = res.cities ? res.cities.map((data: any) => {
                    return {
                        label: data.label,
                        ltext: data.name,
                        lt: data.filter_type,
                        lid: data.id,
                        slug: data.slug || null,
                        district: data.district || null,
                        area: data.area || null,
                        redirect: data.value,
                        location: data.location || null
                    }
                }) : []

                const clusters = res.clusters ? res.clusters.map((data: any) => {
                    return {
                        image: data.primary_image_thumbnail_small_url,
                        label: data.label,
                        ltext: data.name,
                        lt: data.filter_type,
                        lid: data.id,
                        slug: data.slug || null,
                        district: data.district || null,
                        area: data.area || null,
                        redirect: data.value,
                        location: data.location || null
                    }
                }) : []

                const districts = res.districts ? res.districts.map((data: any) => {
                    return {
                        label: data.label,
                        ltext: data.name,
                        lt: data.filter_type,
                        lid: data.id,
                        slug: data.slug || null,
                        district: data.district || null,
                        area: data.area || null,
                        redirect: data.value,
                        location: data.location || null
                    }
                }) : []

                const projects = res.projects ? res.projects.map((data: any) => {
                    console.log(data)
                    return {
                        label: data.label,
                        ltext: data.name,
                        lt: data.filter_type,
                        lid: data.id,
                        slug: data.slug || null,
                        district: data.district || null,
                        area: data.area || null,
                        redirect: data.value,
                        location: data.location || null,
                        image: data.primary_image_thumbnail_small_url
                    }
                }) : []

                const provinces = res.provinces ? res.provinces.map((data: any) => {
                    return {
                        label: data.label,
                        ltext: data.name,
                        lt: data.filter_type,
                        lid: data.id,
                        slug: data.slug || null,
                        district: data.district || null,
                        area: data.area || null,
                        redirect: data.value,
                        location: data.location || null
                    }
                }) : []

                const output = [
                    {
                        title: 'Cities',
                        locations: cities
                    },
                    {
                        title: 'Clusters',
                        locations: clusters
                    },
                    {
                        title: 'Districts',
                        locations: districts
                    },
                    {
                        title: 'Projects',
                        locations: projects
                    },
                    {
                        title: 'Provinces',
                        locations: provinces
                    },
                ].filter(loc => loc.locations.length > 0)

                setInitialSuggestions(output)
                setSuggestions(output)
                // setSuggestions(output.filter(loc => Number(state.tp) === 2 ? loc.title !== lang.apartment : true))
            })
    }

    // Using callback to prevent function get re-rendered
    const debouncedFetchList = useCallback(debounce(fetchList, 300), [state.tp])

    const renderOurProperty = (renderIcon: (className: string) => React.ReactNode, imageUrl: string, title: string, amount: number, url: string, backgroundClass: string, index: number) => {
        const iconClasses = `text-white text-45px mt-2 lg:text-51px opacity-1 transition-opacity duration-150 ease-linear ${classes['our-property-icon']}`

        return (
            <a key={title} href={url} className={`bg-#fffc w-1/2 md:w-1/8 lg:w-1/8 flex-shrink-0 grid grid-cols-1 grid-rows-1 pt-5 ${index === 0 ? 'rounded-tl rounded-bl pl-17.5px pr-7.5px' : index === 7 ? 'rounded-tr rounded-br pr-17.5px pl-7.5px' : 'px-7.5px'}`}>
                <div className={`relative pt-106% overflow-hidden rounded ${classes['our-property-card']}`} style={{ height: isMobile ? '100%' : '160px' }}>
                    <div className={`absolute inset-0 w-full h-full bg-cover bg-center ${classes[backgroundClass]}`}>
                        <div className={`absolute inset-0 w-full h-full opacity-1 z-1 transition-opacity duration-150 ease-linear ${classes['our-property-overlay']}`} style={{ background: 'linear-gradient(180deg, rgba(36, 30, 30, 0) 2.08%, rgba(27, 27, 27, 0.8) 78.12%)' }}>
                        </div>
                        <div className={`inset-0 w-full h-full absolute`} style={{ zIndex: 0 }}>
                            <Image src={imageUrl} alt={title} layout='fill' sizes='100vw' objectFit='cover' />
                        </div>
                        <div className={`absolute inset-0 w-full h-full flex flex-col z-2 items-center py-18px`}>
                            <div className="m-auto">
                                {renderIcon(iconClasses)}
                            </div>
                            <div className="mt-auto text-white text-center">
                                <div className='py-2 table m-auto' style={{ height: '40px' }}>
                                    <p className="whitespace-pre-line table-cell align-middle lg:text-base font-semibold text-center leading-none">
                                        {title}
                                    </p>
                                </div>
                                <p className="text-2xs lg:text-xs text-center">
                                    {amount} Properties
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        )
    }

    const handleSelect = (name: string, val: string) => {
        let value = ''
        let extraData = {}

        if (name.includes('price')) {
            value = val.replace(/[^\d]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.')

            if (name === 'min_price' && value !== '' && state.max_price) {
                extraData = Number(inputNumber(value)) > Number(inputNumber(state.max_price)) ? {
                    max_price: '',
                } : {}
            }

        } else {
            value = val
        }

        setState(prev => ({
            ...prev,
            [name]: value,
            ...extraData,
        }))
    }

    const handleSearch = () => {
        if (redirect) {
            const price_min = state.min_price ? `&lowest_price=${inputNumber(state.min_price)}` : ''
            const price_max = state.max_price ? `&highest_price=${inputNumber(state.max_price)}` : ''

            if (redirect.includes('search')) {
                window.location.href = `${redirect}${state.property_type !== 'All' ? `&property_type=${state.property_type}` : ''}${price_min}${price_max}`
            } else {
                if (state.min_price || state.max_price || (state.property_type !== 'All') && selectedLocation) {
                    const { location_id, location_text, location_type, rdr } = selectedLocation!

                    window.location.href = generateUrl(langCode, `/search?location_type=${location_type}&location_id=${location_id}&location_text=${encodeURIComponent(location_text || '')}&rdr=${encodeURIComponent(rdr || '')}${state.property_type !== 'All' ? `&property_type=${state.property_type}` : ''}${price_min}${price_max}`)
                } else {
                    window.location.href = redirect
                }
            }
        } else {
            const queries = {
                property_type: state.property_type !== 'All' ? state.property_type : null,
                lowest_price: state.min_price ? inputNumber(state.min_price) : null,
                highest_price: state.max_price ? inputNumber(state.max_price) : null,
                query: state.search.length > 3 ? state.search : null,
            }

            window.location.href = generateUrl(langCode, `/search?${serializeToQuery(queries)}`)
        }
    }

    // Handle ketika fetch dibutuhkan
    const onSuggestionsFetchRequested = ({ value, reason }: any) => {
        switch (reason) {
            case 'input-changed':
                const inputValue = value.trim().toLowerCase()
                const escapedValue = inputValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
                if (escapedValue.length >= 2) {
                    debouncedFetchList(escapedValue)
                } else if (suggestions.length > 0) {
                    setSuggestions([])
                }
                break
            case 'input-focused':
                if (state.search && state.search.length >= 2) {
                    setSuggestions(initialSuggestions.filter(suggestion => Number(state.tp) === 2 ? suggestion.title !== lang.apartment : true))
                } else if (suggestions.length > 0) {
                    setSuggestions([])
                }
                break
            case 'escape-pressed':
                debouncedFetchList()
                break
        }
    }

    // Handle ketika fetch diclear
    const onSuggestionsClearRequested = () => {
        setSuggestions([])
    }

    // Fungsi untuk menentukan cara mendapatkan value dari suggestion
    const getSuggestionValue = (suggestion: ILocation) => suggestion.ltext

    // Handle change
    const handleLocationChange = (e: any, { newValue }: any) => {
        setState(prev => ({
            ...prev,
            search: newValue
        }))
    }

    const renderLabel = (text: string) => {
        if (state.search) {
            const regex = new RegExp(`${escapeRegex(state.search)}`, 'gi')

            let retArr = text.replace(regex, (str) => {
                return `:${str}:`
            }).split(':')

            const length = retArr.length

            if (length > 1) {
                return (
                    <p>
                        {
                            retArr.map((val, index) => {
                                if (regex.test(val)) {
                                    return <strong className="font-extrabold" key={index}>{val}</strong>
                                } else {
                                    return <React.Fragment key={index}>{val}</React.Fragment>
                                }
                            })
                        }
                    </p>
                )

            } else {
                return <p>{text}</p>
            }
        } else {
            return <p>{text}</p>
        }
    }

    // Render suggestion
    const renderSuggestion = (suggestion: ILocation) => {
        if (suggestion)
            return (
                <div className="text-sm flex flex-row items-center">
                    {
                        suggestion.location ?
                            <div className='relative w-12 h-12 rounded-md mr-3'>
                                <Image
                                    src={suggestion.image || DEFAULT_IMAGE}
                                    layout='fill'
                                />
                            </div> :
                            <MapPin className='text-main w-4 inline-block mr-3' />
                    }
                    <div className='flex flex-col'>
                        {renderLabel(suggestion.ltext)}
                        {
                            suggestion.location ?
                                <p className="text-xs text-gray-ab leading-tight">{`${suggestion.location}`}</p>
                                : null
                        }
                    </div>
                </div>
            )
    }

    // Render title (Apartemen | Area)
    const renderSectionTitle = (section: ISuggestion) => {
        return (
            <div className='text-lg leading-snug bg-main-white-f2 text-black-#3D3D3D'>
                <strong>
                    {section.title}
                </strong>
            </div>
        )
    }

    // Mendaparkan daftar suggestion dari tiap section (Apartemen | Area)
    const getSectionSuggestions = (section: ISuggestion) => section.locations

    // Handle suggestion yang terpilih
    const onSuggestionSelected = (e: any, { suggestion }: any) => {
        setState(prev => ({
            ...prev,
            ...suggestion
        }))

        setRedirect(suggestion.redirect)

        setSelectedLocation({
            location_type: suggestion.lt,
            location_id: suggestion.lid,
            location_text: suggestion.ltext,
            rdr: suggestion.redirect,
        })

        if (mobileSearchOpen) setMobileSearchOpen(false)
    }

    const handleFocusMobile = () => {
        // handle mobile popup search
        setMobileSearchOpen(true)
    }

    // Input props untuk AutoSuggest
    const inputProps = {
        placeholder: 'Search location / project / cluster',
        value: state.search,
        onChange: handleLocationChange,
    }

    const mobileInputProps = {
        ...inputProps,
        onFocus: handleFocusMobile,
        onClick: handleFocusMobile
    }

    const clearSearch = () => {
        setRedirect(null)
        setState(prev => ({
            ...prev,
            search: '',
        }))
        setSelectedLocation(null)
    }

    const renderShortcut = (icon: React.ReactNode, title: string, url: string) => {
        return (
            <a href={url} className={`mb-3 lg:mb-2 flex-shrink-0 px-1`} style={{ width: isMobile ? `calc((100%) / 3)` : '156px' }}>
                <div className={`${classes.shortcut} mb-3 px-3 bg-white hover:bg-main border h-full text-main border-#EBEBEB hover:border-transparent hover:text-white py-3 lg:py-3 rounded-lg relative cursor-pointer w-full`}>
                    <div className='flex flex-col items-center'>
                        {/* <img src={icon} width={isMobile ? '50' : '64'} height={isMobile ? '50' : '64'} alt="property" className="mb-1 lg:mb-5" /> */}
                        <div className={`${classes.circle} h-10 w-10 lg:h-12 lg:w-h-12 flex items-center justify-center relative mb-3 rounded-full bg-red-#FFEEEF`}>
                            {icon}
                        </div>
                        <div className='px-5 text-center'>
                            <p className='text-center leading-none text-black-#3D3D3D font-semibold text-xs lg:text-base'>{title}</p>
                        </div>
                    </div>
                </div>
            </a>
        )
    }

    const forceStyleMobile: React.CSSProperties = useMemo(() => {
        /* handle using search mobile */
        if (mobileSearchOpen && searchRef.current) {
            return {
                translate: `-36px -${searchRef.current.getBoundingClientRect().top}px`,
                backgroundColor: '#fff',
                position: 'relative',
                zIndex: 21,
                padding: 16,
                width: '100vw',
                height: 72,
                top: 0,
            } as React.CSSProperties
        }

        return { position: 'static' }
    }, [mobileSearchOpen, searchRef])

    return (
        <Master
            isTransparent={true}
            langCode={langCode}
            lang={lang}
            title="Situs Jual Beli Rumah Baru Terbaik & Terpercaya by Sinar Mas Land"
            description="Cari informasi jual beli rumah dan properti online terlengkap dan terbaru dari Sinar Mas Land. Dapatkan harga terbaik langsung dari developer."
            image={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/homepage_banner.png`}
            url={`/`}
            keyword="sinar mas land"
            extraFooter
            ldJsons={ldJsons}
        >
            {/* Start Here */}
            <div
                className={`relative lg:mb-10 mb-5 lg:h-60vh lg:max-h-451px`}
            >
                <div
                    className='absolute inset-0 block md:hidden'
                    style={{ zIndex: -1 }}
                >
                    <Image
                        src={`${listData.homepage_background_banner_mobile_url.replace('\\', '/') || 'https://ecatalog.sinarmasland.com/assets/site-setting-files/1/homepage-background-banner-mobile.jpg'}`}
                        layout="fill"
                        alt="dashboard jumbotron"
                        objectFit='cover'
                        objectPosition='center'
                    />
                </div>
                <div
                    className='absolute inset-0 hidden md:block'
                    style={{ zIndex: -1 }}
                >
                    <Image
                        src={`${listData.homepage_background_banner_desktop_url.replace('\\', '/') || 'https://ecatalog.sinarmasland.com/assets/site-setting-files/1/homepage-background-banner-desktop.jpg'}`}
                        layout="fill"
                        alt="dashboard jumbotron"
                        objectFit='cover'
                        objectPosition='center'
                    />
                </div>

                <div className={`pt-20 px-5 container flex flex-col`}>
                    <h1 className="text-xl self-center text-black font-bold leading-tight text-center px-2 pt-4 pb-0 lg:text-5xl lg:font-bold lg:pt-16 lg:pb-2 mb-5 lg:mb-5">{lang.tagline}</h1>
                    <div className="mt-0 md:w-480 lg:w-800 lg:mt-0 relative mx-auto w-full">
                        <div className={`md:mx-auto bg-white p-4 flex flex-col lg:mt-2 rounded-2xl relative`}>
                            <div className="flex flex-col lg:flex-row items-center">
                                <div className='flex lg:flex-1 flex-shrink-0 flex-row items-center mb-4 lg:mb-0 w-full lg:w-auto'>
                                    <div className={`lg:flex-0 flex-1 mb-0 lg:mr-3 ${mobileSearchOpen ? 'z-30' : 'z-3'}`}>
                                        {
                                            mobileSearchOpen ?
                                                <div className='fixed inset-0 z-21 bg-white'></div> :
                                                null
                                        }
                                        <div ref={searchRef} className="transform duration-200 ease-out w-full search-autosuggest navbar-autosuggest bg-white" style={forceStyleMobile}>
                                            <ReactAutoSuggest
                                                multiSection={true}
                                                suggestions={suggestions}
                                                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                                onSuggestionsClearRequested={onSuggestionsClearRequested}
                                                getSuggestionValue={suggestion => getSuggestionValue(suggestion as ILocation)}
                                                renderSuggestion={suggestion => renderSuggestion(suggestion as ILocation)}
                                                renderSectionTitle={renderSectionTitle}
                                                getSectionSuggestions={suggestion => getSectionSuggestions(suggestion as ISuggestion)}
                                                onSuggestionSelected={onSuggestionSelected}
                                                inputProps={isMobile ? mobileInputProps : inputProps}
                                                theme={{
                                                    container: 'w-full react-autosuggest__container',
                                                    containerOpen: 'react-autosuggest__container--open',
                                                    input: 'react-autosuggest__input',
                                                    inputOpen: 'react-autosuggest__input--open',
                                                    inputFocused: 'react-autosuggest__input--focused',
                                                    suggestionsContainer: 'react-autosuggest__suggestions-container',
                                                    suggestionsContainerOpen: `react-autosuggest__suggestions-container--open ${mobileSearchOpen ? 'no-top no-shadow full-height-mobile' : `rounded-xl custom-position`} no-padding`,
                                                    suggestionsList: 'react-autosuggest__suggestions-list pb-5',
                                                    suggestion: 'react-autosuggest__suggestion',
                                                    suggestionFirst: 'react-autosuggest__suggestion--first',
                                                    suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
                                                    sectionContainer: 'react-autosuggest__section-container',
                                                    sectionContainerFirst: 'react-autosuggest__section-container--first',
                                                    // sectionTitle: 'react-autosuggest__section-title'
                                                    sectionTitle: 'py-3 px-4 bg-main-white-f2'
                                                }}
                                                renderInputComponent={(inputProp: any) =>
                                                    <div className="flex flex-row items-center">
                                                        {
                                                            mobileSearchOpen ?
                                                                <div className='mr-3'>
                                                                    <FontAwesomeIcon onClick={() => setMobileSearchOpen(false)} icon={faChevronLeft} className='w-5 transform scale-110 text-black-33' />
                                                                </div> :
                                                                null
                                                        }
                                                        <div className={`${classes.searchbar} ${mobileSearchOpen ? "border border-#EBEBEB py-2 flex-1 rounded-xl" : ''} flex-1 transition-colors pr-3 lg:pr-0 duration-150 ease-linear flex flex-row items-center rounded-sm`}>
                                                            {/* <span className="flex items-center justify-center">
                                                            <SearchLight className="fill-current w-4" />
                                                        </span> */}
                                                            <span className="flex items-center justify-center pl-2">
                                                                <SearchLight className={`${mobileSearchOpen ? "w-3" : "w-5"} fill-current text-gray-#8F8F8F`} />
                                                            </span>
                                                            <input
                                                                {...inputProp}
                                                                className={`w-full bg-transparent lg:bg-white truncate text-sm md:text-lg outline-none ml-3 text-black-48`}
                                                                autoComplete="off"
                                                                name="off"
                                                                onFocus={(e) => {
                                                                    if (inputProp.onFocus) inputProp.onFocus(e)
                                                                    if (searchRef.current && ['xs', 'sm', 'md'].includes(breakpoint))
                                                                        window.scrollTo({
                                                                            top: searchRef.current.offsetTop - 58,
                                                                            behavior: 'smooth'
                                                                        })
                                                                }
                                                                }
                                                            />
                                                            {
                                                                (state.search || redirect || selectedLocation !== null) ?
                                                                    <span className="flex items-center justify-center pl-2 cursor-pointer" onClick={clearSearch}>
                                                                        {/* <SearchLight className="fill-current w-4" /> */}
                                                                        <FontAwesomeIcon icon={faTimesCircle} width="0" className="fill-current w-4 opacity-25" />
                                                                    </span>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='self-stretch w-1 border-r border-#EBEBEB hidden lg:block' />
                                    <button onClick={() => setFilterOpen(!filterOpen)} className='text-main lg:ml-10 flex flex-row items-center outline-none focus:outline-none mr-5'>
                                        <FontAwesomeIcon icon="sliders-h" width="0" className={`w-5 text-main mr-2`} />
                                        Filter
                                        {
                                            filterCount ?
                                                <p className='w-5 h-5 rounded-full bg-main ml-1 text-white font-bold text-sm'>{filterCount}</p> :
                                                null
                                        }
                                    </button>

                                </div>
                                <button
                                    className="rounded-lg w-full lg:w-auto lg:flex-0 focus:shadow-outline py-2 px-4 lg:px-5 bg-main hover:bg-main-dark active:bg-main-dark lg:py-2 flex items-center justify-center lg:self-stretch text-white text-base font-normal focus:outline-none transition-colors duration-150 ease-linear"
                                    onClick={handleSearch}
                                >
                                    <SearchLight className='mr-2 w-4 lg:hidden block' />
                                    <span>
                                        {lang.search_property}
                                    </span>
                                </button>
                            </div>
                        </div>

                        {
                            <FilterModal
                                filterData={{
                                    max_price: state.max_price,
                                    min_price: state.min_price,
                                    property_type: state.property_type
                                }}
                                lang={lang}
                                onChangeFilter={handleSelect}
                                onResetFilter={() => setState({
                                    search: '',
                                    property_type: 'All',
                                    min_price: '',
                                    max_price: '',
                                })}
                                filterCount={filterCount}
                                isOpen={filterOpen}
                                close={() => setFilterOpen(false)}
                                isMobile={isMobile}
                            />
                        }
                    </div>

                    <div className='hidden lg:flex flex-no-wrap flex-row gap-6 lg:gap-30px mx-auto mt-6 mb-5'>
                        {
                            exploreProperty.map((property) => (
                                <div className='flex flex-col items-center'>
                                    <a
                                        href={property.url}
                                        className={`${classes.exploreShadow} block p-4 rounded-lg mb-2 bg-white text-main hover:bg-main hover:text-white transition-colors duration-150 ease-in-out`}
                                        style={{ boxShadow: 'box-shadow: 0px 0px 0px 4px currentColor;' }}
                                    >
                                        {property.renderIcon('w-6 h-6 lg:w-8 lg:h-8')}
                                    </a>
                                    <span className='text-white text-xs lg:text-sm text-center font-bold'>{property.title}</span>
                                </div>
                            ))
                        }
                    </div>

                    <div className='block pt-5 lg:hidden overflow-hidden -mx-5' style={{ width: 'calc(100% + (40px))' }}>
                        <div className='w-full'>
                            <LegacyCarousel
                                show={6}
                                fixedOffset={2 * ((mobileWidth - 40) / 4)}
                                withIndicator
                                indicatorContainerClasses='pt-2 mb-3'
                                withoutHideOverflow
                                sliderClass='mx-5'
                            >
                                {
                                    exploreProperty.map((property, index) => (
                                        <div className={`flex flex-col items-center relative pb-4 flex-shrink-0 mx-4`} style={{ width: 'calc((100% / 4) - 2rem)' }}>
                                            <a
                                                href={property.url}
                                                className={`${classes.exploreShadow} block p-3 lg:p-4 rounded-lg mb-2 bg-white text-main hover:bg-main hover:text-white transition-colors duration-150 ease-in-out`}
                                                style={{ boxShadow: 'box-shadow: 0px 0px 0px 4px currentColor;' }}
                                            >
                                                {property.renderIcon('w-6 h-6 lg:w-8 lg:h-8')}
                                            </a>
                                            <span className='absolute whitespace-no-wrap bottom-0 inset-x-auto text-white text-xs lg:text-sm text-center font-bold'>{property.title}</span>
                                        </div>
                                    ))
                                }
                            </LegacyCarousel>
                        </div>
                    </div>

                    {/* <div className={`px-4 container block ${classes['shifted-card']}`}>
                        <div className="-mx-5 md:-mx-1">
                            <LegacyCarousel
                                show={isMobile ? 1 : 8}
                                showSliderTranslateOverride={isMobile ? 2 : 0}
                                // isRepeatedProps={['xs', 'sm', 'md'].includes(breakpoint)}
                                withIndicator={['xs', 'sm', 'md'].includes(breakpoint)}
                                indicatorSpacing="pt-5"
                                containerClasses="px-0 lg:px-0"
                                indicatorClasses={{
                                    passive: classes['grey-passive-dots'],
                                    far: classes['grey-far-dots']
                                }}
                                sliderClass="px-5 md:px-0"
                                sliderOffset={20}
                                withoutArrow
                            >
                                {
                                    exploreProperty.map(({ amount, imageUrl, renderIcon, title, url, backgroundClass }, index) =>
                                        renderOurProperty(renderIcon, imageUrl, title, amount, url, backgroundClass, index)
                                    )
                                }
                            </LegacyCarousel>
                        </div>
                    </div> */}
                </div>
            </div>
            {/* End Here */}

            {/* <div className='container hidden md:block px-3 mb-8'>
                <div className='flex flex-col items-start'>
                    <p className='text-32px font-semibold'>{lang.explore_our}</p>
                    <span className='text-gray-#8F8F8F'>{lang.explore_our_subtitle}</span>
                </div>
            </div> */}
            <div className='w-full overflow-hidden lg:container mb-1 lg:mb-0'>
                <div className='hidden lg:flex mx-5 items-stretch lg:mx-auto lg:flex-wrap flex-no-wrap justify-center relative gap-4'>
                    {renderShortcut(<HomepageCompare className="h-8 lg:h-12" />, 'Compare', '/compare-unit')}
                    {renderShortcut(<HomepageCalculatorIcon className="h-8 lg:h-12" />, 'KPR Calculator', '/kpr-calculator')}
                    {renderShortcut(<Homepage360Icon className="h-8 lg:h-12" />, 'Virtual Tour', '/search?property_type=All&sort=asc&has_360=1&page=1')}
                    {renderShortcut(<HomepagePromoIcon className="h-8 lg:h-12" />, 'Hot Promo', '/infiniteliving')}
                    {/* {renderShortcut("/static/images/svg/sky.svg", 'Aerial View')} */}
                    {renderShortcut(<HomepageContactAgentIcon className="h-8 lg:h-12" />, 'Contact Agent', '/contact-agent')}
                    {renderShortcut(<HomepageAerialIcon className="h-8 lg:h-12" />, 'Aerial View', '/aerial-view')}
                </div>
                <div className='w-full lg:hidden block'>
                    <LegacyCarousel
                        show={5}
                        fixedOffset={3 * ((mobileWidth - 40) / 3)}
                        withoutHideOverflow
                        withIndicator
                        sliderClass='items-strech'
                        containerClasses='w-full'
                    >
                        {renderShortcut(<HomepageCompare className="h-8 lg:h-12" />, 'Compare', '/compare-unit')}
                        {renderShortcut(<HomepageCalculatorIcon className="h-8 lg:h-12" />, 'KPR Calculator', '/kpr-calculator')}
                        {renderShortcut(<Homepage360Icon className="h-8 lg:h-12" />, 'Virtual Tour', '/search?property_type=All&sort=asc&has_360=1&page=1')}
                        {renderShortcut(<HomepagePromoIcon className="h-8 lg:h-12" />, 'Hot Promo', '/infiniteliving')}
                        {/* {renderShortcut("/static/images/svg/sky.svg", 'Aerial View')} */}
                        {renderShortcut(<HomepageContactAgentIcon className="h-8 lg:h-12" />, 'Contact Agent', '/contact-agent')}
                        {renderShortcut(<HomepageAerialIcon className="h-8 lg:h-12" />, 'Aerial View', '/aerial-view')}
                    </LegacyCarousel>
                </div>
            </div>

            {
                listData['homepage-banner'].length > 0 &&
                // <Promotions homepageBanner={listData['homepage-banner']} />
                <DesktopPromotions homepageBanner={listData['homepage-banner']} />
            }

            {/* New Launch */}
            <NewLaunch
                breakpoint={breakpoint}
                data={listData.new_listings}
                isMobile={isMobile}
                lang={lang}
                state={state}
            />

            {/* Exploce Cities */}
            <ExploreCities
                data={listData.projects}
                listingCounterCity={listData.city_counter}
            />

            {
                promotionsData.length > 0 &&
                <SmartMoveSection
                    classes={classes}
                    isMobile={isMobile}
                    lang={lang}
                    promotions={promotionsData}
                />
            }

            {/* Explore Project */}
            <ExploreProject
                breakpoint={breakpoint}
                classes={classes}
                isMobile={isMobile}
                projects={listData.projects}
                promotions={promotionsData}
            />

            {/* Helpul Tools Section */}
            <HelpfulToolsSection />

            {/* Meet Our Top Agents */}
            <MeetOurTopAgents
                dataAgent={listData.top_agents}
                isMobile={isMobile}
                lang={lang}
            />

            {/* <Your360Dream
                lang={lang}
            /> */}

            <ArticleListSection isMobile={isMobile} posts={listData.latest_posts} />

            <HomepageArticle isMobile={isMobile} />

            <div className="h-8 md:h-16 w-full bg-footer-black"></div>
        </Master>
    )
}