import dynamic from 'next/dynamic'
import React, { useCallback, useEffect } from 'react'
import Input from '../../forms/Input'
import { convertNumberFormat, handleScroll, inputNumber } from '../../../utils/Helper';
import { useInView } from 'react-intersection-observer';
import { priceHelperOptionByProperty } from '../../../utils/StaticData';
import { PropertyType } from '../../../utils/Types';

/**
 * Dynamic Import
 */
const HomeIcon = dynamic(() => import('../../icons/HomeIcon'));
const ApartmentIcon = dynamic(() => import('../../icons/ApartmentIcon'));
const ShophouseIcon = dynamic(() => import('../../icons/ShophouseIcon'));
const BusinessLoftIcon = dynamic(() => import('../../icons/BusinessLoftIcon'));
const KavlingLotIcon = dynamic(() => import('../../icons/KavlingLotIcon'));
const KioskIcon = dynamic(() => import('../../icons/KioskIcon'));
const BoutiqueIcon = dynamic(() => import('../../icons/BoutiqueIcon'));
const WarehouseIcon = dynamic(() => import('../../icons/WarehouseIcon'));

export interface IFilterData {
    property_type: string,
    min_price: string,
    max_price: string
}

type Props = {
    onChangeFilter: (name: string, value: string) => void
    onResetFilter: () => void
    filterData: IFilterData
    lang: any
    filterCount: number
    isOpen: boolean
    close: () => void
}


const FilterMain = (props: Props) => {
    const propertyTypeOptions = [
        {
            value: 'Residential',
            label: props.lang.residential,
            Icon: HomeIcon
        },
        {
            value: 'Apartment',
            label: props.lang.apartment,
            Icon: ApartmentIcon
        },
        {
            value: 'Ruko',
            label: props.lang.ruko,
            Icon: ShophouseIcon
        },
        {
            value: 'Business Loft',
            label: props.lang.business_loft,
            Icon: BusinessLoftIcon
        },
        {
            value: 'Kavling',
            label: props.lang.kavling,
            Icon: KavlingLotIcon
        },
        {
            value: 'Kiosk',
            label: props.lang.kiosk,
            Icon: KioskIcon
        },
        {
            value: 'Warehouse',
            label: props.lang.warehouse,
            Icon: WarehouseIcon
        },
        {
            value: 'Boutique SOHO',
            label: props.lang.boutique_soho,
            Icon: BoutiqueIcon
        },
    ]

    const isPriceTagActive = useCallback(({ min, max }: { min: string, max: string }) => {
        if (min === props.filterData.min_price && max === props.filterData.max_price) {
            return true
        }

        return false
    }, [props.filterData])

    const { ref, inView } = useInView({
        threshold: 0,
    })

    const handleScroll = () => {
        if (!inView) props.close()
    }

    useEffect(() => {
        window.document.addEventListener('scrollend', handleScroll)
        return () => {
            window.document.removeEventListener('scrollend', handleScroll)
        }
    }, [inView])

    return (
        <>
            <div ref={ref} className='static lg:absolute z-4 text-black-#3D3D3D shadow-none lg:shadow-filter-modal rounded-lg overflow-hidden w-full' style={{ top: 'calc(100% + 20px)' }}>
                <div className='bg-main-white-f2 flex flex-row justify-between items-center py-3 px-4'>
                    <h2 className='text-lg font-semibold'>Filter</h2>
                    <button disabled={!props.filterCount} onClick={props.onResetFilter} className={`text-md ${props.filterCount ? 'text-main' : 'text-gray-#8a8a8a'}`}>Reset</button>
                </div>
                <div className='bg-white overflow-y-auto'>
                    <div className='py-6 px-4'>
                        <p className='mb-4 font-bold'>Type</p>
                        <div className='md:grid md:grid-cols-6 flex flex-row flex-wrap gap-2'>
                            {
                                propertyTypeOptions.map(({ Icon, ...option }) => (
                                    <div
                                        onClick={() => props.onChangeFilter('property_type', option.value === props.filterData.property_type ? 'All' : option.value)}
                                        className={`${option.value === props.filterData.property_type ? 'bg-main text-white' : ''} transition-colors duration-150 ease-in-out rounded-lg cursor-pointer border border-#EBEBEB hover:bg-main hover:text-white p-2 py-3 flex-0 ${option.value === "Boutique SOHO" ?  'w-110px' : 'w-90px' }  lg:w-auto lg:flex-1`}
                                    >
                                        <div className='mb-3 lg:mb-4'>
                                            <Icon className='h-18px hover:text-white' />
                                        </div>
                                        <span className='text-xs leading-3 whitespace-no-wrap'>{option.label}</span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className='border-b border-#EBEBEB mx-4'></div>
                    <div className='py-6 px-4'>
                        <p className='mb-4 font-bold'>Price Range</p>
                        <div className='flex flex-row flex-no-wrap gap-2 mb-4'>
                            <Input
                                name='min_price'
                                value={props.filterData.min_price}
                                placeholder='Lowest Price'
                                label=' '
                                labelAlwaysUp
                                onChange={(e) => props.onChangeFilter('min_price', e.target.value)}
                                outlined
                                inputClassName='text-sm pt-3 pb-3 pl-12 border rounded-md text-gray-#8a8a8a'
                                className='flex-1'
                                rounded
                                startAdornment={<div className=' flex items-center justify-center font-bold text-xs lg:text-sm h-full text-gray-#8a8a8a w-12'>Rp. </div>}
                            />
                            <Input
                                name='max_price'
                                value={props.filterData.max_price}
                                placeholder='Highest Price'
                                label=' '
                                labelAlwaysUp
                                onChange={(e) => props.onChangeFilter('max_price', e.target.value)}
                                outlined
                                inputClassName='text-sm pt-3 pb-3 pl-12 border rounded-md text-gray-#8a8a8a'
                                className='flex-1'
                                rounded
                                startAdornment={<div className=' flex items-center justify-center font-bold text-xs lg:text-sm h-full text-gray-#8a8a8a w-12'>Rp. </div>}
                            />
                        </div>
                        <div className='flex flex-row flex-wrap lg:flex-no-wrap gap-2'>
                            {
                                priceHelperOptionByProperty[props.filterData.property_type as PropertyType || `All`].map((price) => (
                                    <button
                                        key={price.label}
                                        onClick={() => {
                                            props.onChangeFilter('min_price', String(price.value.min))
                                            props.onChangeFilter('max_price', String(price.value.max))
                                        }}
                                        className={`${isPriceTagActive(price.value) ? 'bg-main text-white' : 'bg-main-white-f2 text-black-#3D3D3D'} outline-none focus:outline-none transition-colors duration-150 ease-in-out font-semibold  rounded-full text-xs lg:text-sm flex-0 py-6px px-3`}
                                    >
                                        {price.label}
                                    </button>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FilterMain